.logo a img {
	display: none;
}

.bg_dark {
	background: #0a080e;
	color     : #fff;
}

.styleswitch.layoutswitch {
	background        : none repeat scroll 0 0 #f4f4f4;
	border            : medium none !important;
	color             : #fff !important;
	display           : inline-block;
	height            : auto;
	margin            : 3px 0;
	padding           : 2px;
	text-align        : center;
	text-transform    : uppercase;
	width             : 100%;
	text-decoration   : none !important;
	-webkit-transition: all .3s ease-out;
	-moz-transition   : all .3s ease-out;
	-ms-transition    : all .3s ease-out;
	-o-transition     : all .3s ease-out;
	transition        : all .3s ease-out;
}

.styleswitch.layoutswitch:hover {
	background: #000 !important;
}

.styleswitch {
	background-color: #e425e9;
	display         : inline-block;
	margin          : 4px 8px;
	width           : 25px;
	height          : 25px;
	border-radius   : 5px;
}

.styleswitch:active,
.styleswitch:focus {
	border: 5px solid #000;
}

#switcher-body .container {
	background: #fff;
	margin    : 50px auto;
	border    : 1px solid #dedede;
	padding   : 40px;
}

.switcher {
	width        : 50px;
	height       : 50px;
	display      : inline-block;
	margin       : 4px 8px;
	border-radius: 5px;
	border       : 0px solid #000;
}

.demo_changer {
	z-index    : 99999;
	position   : fixed;
	right      : -270px;
	font-weight: 400 !important;
	height     : 100%;
}

.demoPanel {
	position: relative;
}

.evo-pop {
	z-index: 10000;
	width  : 204px;
	padding: 3px 3px 0
}

.evo-pop-ie {
	z-index: 10000;
	width  : 212px;
	padding: 3px
}

.evo-palette td {
	font-size: 1px;
	border   : solid 1px #c0c0c0;
	padding  : 7px;
	cursor   : pointer
}

.evo-palette tr.top td {
	border-bottom: 0
}

.evo-palette tr.in td {
	border-top   : 0;
	border-bottom: 0
}

.evo-palette tr.bottom td {
	border-top: 0
}

.evo-palette div.sep {
	height: 3px
}

.evo-palette,
.evo-palette-ie {
	border-collapse: separate;
	border-spacing : 4px 0;
	border-collapse: expression('separate', cellSpacing='2px')
}

.evo-palette th,
.evo-palette-ie th {
	border     : 0;
	padding    : 5px 3px;
	text-align : left;
	font-weight: normal;
	background : transparent !important
}

.evo-palette-ie td {
	font-size: 1px;
	border   : solid 1px #c0c0c0;
	padding  : 7px;
	cursor   : pointer
}

.evo-palette2,
.evo-palette2-ie {
	margin         : auto;
	border-collapse: collapse
}

.evo-palette2 td,
.evo-palette2-ie td {
	font-size: 1px;
	cursor   : pointer
}

.evo-palette2 td {
	padding: 6px 7px
}

.evo-palette2-ie td {
	padding: 5px
}

.evo-palcenter {
	padding   : 5px;
	text-align: center
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
	border: solid 1px #c3c3c3;
	width : 20px;
	height: 20px;
	float : right
}

.evo-colorind {
	position: relative;
	top     : 2px
}

.evo-colorind-ie {
	position: relative;
	top     : -23px
}

.evo-colorbox-ie {
	font-size: 8px;
	padding  : 3px 9px !important
}

.evo-colortxt-ie {
	position: relative;
	top     : -6px
}

.evo-pop:after,
.evo-pop-ie:after,
.evo-colorind:after,
.evo-colorind-ie:after,
.evo-colorind-ff:after,
.evo-color span:after,
.evo-cHist:after {
	content   : ".";
	display   : block;
	height    : 0;
	clear     : both;
	visibility: hidden;
	font-size : 0
}

.evo-color {
	width  : 94px;
	padding: 1px 3px 0 4px
}

.evo-color div {
	border       : solid 1px #808080;
	border-right : solid 1px #c0c0c0;
	border-bottom: solid 1px #c0c0c0;
	padding      : 3px;
	margin-bottom: 5px;
	width        : 10px;
	height       : 10px;
	float        : left
}

.evo-color span {
	font-size: 15px;
	margin   : 1px 0 4px 3px;
	float    : left
}

.evo-sep {
	height   : 10px;
	font-size: 0
}

.evo-more {
	padding  : 4px 5px 4px;
	font-size: smaller
}

.evo-cHist {
	padding: 3px
}

.evo-cHist div {
	cursor : pointer;
	border : solid 1px #c0c0c0;
	padding: 3px;
	margin : 5px;
	width  : 10px;
	height : 10px;
	float  : left
}

a.evo-hist {
	margin-left: 6px
}

.evo-pointer {
	cursor: pointer
}

.evo-pop {
	background: #FFFFFF;
	border    : 1px solid #DEDEDE;
	padding   : 10px !important;
	width     : 225px !important;
	z-index   : 10000;
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
	border  : 1px solid #C3C3C3;
	height  : 20px;
	position: absolute;
	top     : 11px;
	right   : 5px;
	width   : 20px;
}

.demo_changer p {
	margin: 2px 0;
}

.demo_changer .dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
	background: #fff;
}

.demo_changer select:active,
.demo_changer select:focus,
.demo_changer select:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover,
.demo_changer .btn:active,
.demo_changer .btn:focus,
.demo_changer .btn:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover {
	box-shadow: none !important;
}

.demo_changer .button {
	-moz-user-select: none;
	user-select     : none;
	background-image: none;
	border          : 1px solid #ddd;
	border-radius   : 3px;
	cursor          : pointer;
	display         : inline-block;
	font-size       : 14px;
	font-weight     : normal;
	background      : #fff;
	line-height     : 1.42857;
	margin-bottom   : 0;
	width           : 145px !important;
	padding         : 6px 12px;
	text-align      : center;
	vertical-align  : middle;
	white-space     : nowrap;
}

.demo_changer .img-thumbnail {
	height          : 35px;
	background-color: #fff;
	border          : 1px solid #ddd;
	margin-bottom   : 5px;
	width           : 46% !important;
	padding         : 2px;
}

.demo_changer img {
	border       : 0px solid #f4f7fe;
	margin       : 2px;
	border-radius: 3px;
	background   : #ffffff;
	width        : 50px;
	height       : 50px;
}

.demo_changer hr {
	border-color: #E0E0E0;
}

.demo_changer,
.demo_changer p {
	color: #1d212f !important;
}

.demo_changer h4 {
	font-size     : 14px;
	padding-bottom: 0;
	text-align    : left;
	padding       : 10px;
	font-weight   : 600;
	border-bottom : 1px solid #ededf9;
	background    : #e8ebf5;
	border-top    : 1px solid #ededf9;
	margin        : 10px 0 !important;
	text-transform: uppercase;
}

.demo_changer i {
	color     : #fff;
	font-size : 21px;
	margin-top: 10px;

}

/*.demo_changer .btn {
	 margin-top: 10px;
 }*/
.demo_changer .demo-icon {
	cursor       : pointer;
	text-align   : center;
	padding      : 2px;
	float        : left;
	height       : 40px;
	line-height  : 38px;
	width        : 40px;
	border-radius: 5px 0 0 5px !important;
	top          : 17rem;
	position     : relative;
}

@media (max-width: 992px) {
	.demo_changer .form_holder {
		height: 100vh;
	}
}

@media (min-width: 992px) {
	.demo_changer .form_holder {
		height: 100vh;
	}
}

.demo_changer .form_holder {
	background   : #fff;
	border-radius: 0;
	cursor       : pointer;
	float        : right;
	font-size    : 12px;
	padding      : 0 7px;
	width        : 270px;
	box-shadow   : 0px 8px 14.72px 1.28px rgba(130, 130, 134, 0.2);
	border-left  : 1px solid #ededf9;
}

.demo_changer .form_holder p {
	font-size: 12px;
}

.color_display_2 {
	clear         : both;
	color         : #FFFFFF;
	display       : inline-block;
	font-size     : 12px;
	width         : 31px !important;
	height        : 31px;
	margin-bottom : 7px;
	padding       : 4px 10px;
	text-align    : center;
	text-shadow   : 0 -1px 0 rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
	box-shadow    : 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
}

.color_display_2 a {
	color          : #fff;
	text-decoration: none;
}

.color_display_2 a:focus,
.color_display_2 a:hover {
	color          : #ededed !important;
	text-decoration: none;
}

.demoPanel {
	margin : 0;
	padding: 0;
	width  : 125px;
}

.predefined_styles {
	padding   : 0 5px;
	text-align: center;

}

@media only screen and (min-width: 768px) {
	.demo_changer {
		top: 0;
	}

	.demo_changer .demo-icon {
		height: 40px;
		width : 40px;
	}

	.demo_changer i {
		font-size: 17px;
	}
}

@media only screen and (max-width: 768px) {
	.demo_changer {
		top: 0px;
	}

	.demo_changer i {
		font-size : 21px;
		margin-top: 8px;
	}

	.demo_changer .demo-icon {
		height: 40px;
		width : 40px;
	}

	.demo_changer {
		display: block;
	}
}

.bootstrap-select.show-menu-arrow.open>.btn {
	z-index: 2051;
}

.bootstrap-select .btn:focus {
	outline       : thin dotted #333333 !important;
	outline       : 5px auto -webkit-focus-ring-color !important;
	outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
	overflow  : hidden;
	position  : absolute;
	left      : 12px;
	right     : 25px;
	text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
	position      : absolute;
	top           : 50%;
	right         : 12px;
	margin-top    : -2px;
	vertical-align: middle;
}

.bootstrap-select.btn-group>.disabled,
.bootstrap-select.btn-group .dropdown-menu li.disabled>a {
	cursor: not-allowed;
}

.bootstrap-select.btn-group>.disabled:focus {
	outline: none !important;
}

.bootstrap-select.btn-group[class*="span"] .btn {
	width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
	min-width         : 100%;
	-moz-box-sizing   : border-box;
	-webkit-box-sizing: border-box;
	box-sizing        : border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
	position             : static;
	border               : 0;
	padding              : 0;
	margin               : 0;
	-webkit-border-radius: 0;
	-moz-border-radius   : 0;
	border-radius        : 0;
	-webkit-box-shadow   : none;
	-moz-box-shadow      : none;
	box-shadow           : none;
}

.bootstrap-select.btn-group .dropdown-menu dt {
	display: block;
	padding: 3px 20px;
	cursor : default;
}

.bootstrap-select.btn-group .div-contain {
	overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-menu li {
	position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li>a.opt {
	position    : relative;
	padding-left: 35px;
}

.bootstrap-select.btn-group .dropdown-menu li>a {
	cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
	font-weight: normal;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark {
	display   : inline-block;
	position  : absolute;
	right     : 15px;
	margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
	display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:focus small,
.bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled)>a small {
	color: #64b1d8;
	color: rgba(255, 255, 255, 0.4);
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
	font-weight: normal;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
	content            : '';
	display            : inline-block;
	border-left        : 7px solid transparent;
	border-right       : 7px solid transparent;
	border-bottom      : 7px solid #CCC;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position           : absolute;
	bottom             : -4px;
	left               : 9px;
	display            : none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
	content      : '';
	display      : inline-block;
	border-left  : 6px solid transparent;
	border-right : 6px solid transparent;
	border-bottom: 6px solid white;
	position     : absolute;
	bottom       : -4px;
	left         : 10px;
	display      : none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
	bottom          : auto;
	top             : -3px;
	border-top      : 7px solid #ccc;
	border-bottom   : 0;
	border-top-color: rgba(0, 0, 0, 0.2);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
	bottom       : auto;
	top          : -3px;
	border-top   : 6px solid #ffffff;
	border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
	right: 12px;
	left : auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
	right: 13px;
	left : auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after {
	display: block;
}

.bootstrap-select.btn-group .no-results {
	padding   : 3px;
	background: #f5f5f5;
	margin    : 0 5px;
}

.mobile-device {
	position: absolute;
	top     : 0;
	left    : 0;
	display : block !important;
	width   : 100%;
	height  : 100% !important;
	opacity : 0;
}

.bootstrap-select.fit-width {
	width: auto !important;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
	position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
	position  : static;
	top       : auto;
	margin-top: -1px;
}

.control-group.error .bootstrap-select .dropdown-toggle {
	border-color: #b94a48;
}

.bootstrap-select-searchbox {
	padding: 4px 8px;
}

.bootstrap-select-searchbox input {
	margin-bottom: 0;
}

.dark-mode .dark-switcher {
	display: none !important;
}

.dark-mode .light-switcher {
	display: flex !important;
}

.light-switcher {
	display: none !important;
}

.switch-buttons {
	margin-bottom: 0;
	padding      : 3px;
}

.switch-buttons li {
	width     : 50%;
	float     : left;
	list-style: none;
}

.switch-buttons span {
	font-weight: 500;
	font-size  : 12px;
}

.swticher-link {
	position: absolute;
	width   : 100%;
	height  : 100%;
	left    : 0;
	right   : 0;
}

/* RTL */

.rtl .demo_changer {
	transition: all 0.3s ease;
	left      : -270px;
	right     : auto !important;
}

.rtl .demo_changer.active {
	transition: all 0.3s ease;
	right     : auto !important;
	left      : 0;
}

.rtl .demo_changer .form_holder {
	float       : left;
	border-right: 1px solid #e9edf4;
	border-left : inherit;
}

.rtl .demo_changer h4 {
	text-align: right;
}

.rtl .onoffswitch2-label:before {
	left : 13px;
	right: inherit;
}

.rtl .onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
	left : 0px;
	right: inherit;
}

.rtl .ps>.ps__rail-y {
	left : 0 !important;
	right: auto !important;
}